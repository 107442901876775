@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

:root{
  /*Colors*/
  --clrLimeGreen: hsl(163, 72%, 41%);
  --clrBrightRed: hsl(356, 69%, 56%);
  --clrFacebook: hsl(208, 92%, 53%);
  --clrTwitter: hsl(185, 70%, 53%);
  --clrYoutube: hsl(348, 97%, 39%);
  --clrInstagram: linear-gradient(to right, hsl(37, 97%, 70%) 10%, hsl(329, 70%, 50%) 50%) 1;


  /*Dark Theme*/
  --clrDarkTheme:
  linear-gradient(to right, hsl(210, 78%, 56%) 5%,  hsl(146, 68%, 55%) 95%);
  --clrLightTheme:hsl(240, 3%, 93%);

  
  /*Dark Theme*/
  --clrVeryDarkBlueBG: hsl(230, 17%, 14%);
  --clrDarkBlueTop: hsl(232, 19%, 15%);
  --clrDarkDesaturatedBlue: hsl(228, 28%, 20%);
  --clrDesatBlue:hsl(228, 34%, 66%);

  /*Light Theme*/
  --clrVeryPaleBlue: hsl(225, 100%, 98%);
  --clrLightGrayishBlue: hsl(228,29%,20%);
  --clrDarkGrayishBlue:hsl(228, 12%, 44%);
  --clrVeryDarkBlue: hsl(230, 17%, 14%)
}

*,
*::after,
*::before{
  padding:0;
  margin: 0;
  box-sizing: border-box;
}

 body{
  font-family: 'Inter', sans-serif;
  overflow: hidden;
  height: 100vh;
  transition: background 0.3s ease-in-out;
}

.container{
  max-width: 1240px;
  margin: 50px auto 75px auto ;
}

header {
  display: flex;
  justify-content: space-between;
  color:#fff;
  margin-bottom: 50px;
}

.light-theme header {
  color:#000;
}

header p, label {
  color:var(--clrDesatBlue);
  font-weight: 700;
  font-size: 14px;
}

.dashboard{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 50px;
  color:#fff;
}


/*Slider Button*/
.label{
  display: flex;
  gap:15px;
  align-items: center;

}

.switch{
  position: relative;
  display: block;
  height:26px;
  width: 55px;
}

.switch input{
  opacity: 0;
  height: 0;
  width: 0;
}

.switch span {
  position: absolute;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  background:var(--clrDarkTheme);
  border-radius: 20px;
  transition:  all 0.3s ease-in-out;
}

.switch span::before {
  content:'';
  position: absolute;
  height: 20px;
  width: 20px;
  background: var(--clrLightGrayishBlue);
  border-radius: 20px;
  left:4px;
  top:50%;
  margin-top: -10px;
  transition: all 0.3s ease-in-out;
}

/*Slider background change when checked */
.switch input:checked + span{
background: var(--clrDarkGrayishBlue);
}

.switch input:checked + span::before{
  background: #fff;
  transform: translateX(27px);
}

.card-container{
  border-radius: 5px;
  overflow: hidden
}

.card-main{
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 padding: 20px;
 background: var(--clrLightGrayishBlue);
 color:#fff;
}

.light-theme .card-main{
  background:var(--clrLightTheme);
  color:#000;
}

.card-header{
  display: flex;
  gap:10px;
  margin: 15px 0;
  color:var(--clrDesatBlue);
  font-weight: 700;
  font-size: 12px;
}

.card-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

.card-body h1 {
  font-size: 50px;
  margin-bottom: 5px;
}

.card-body p {
  text-transform: uppercase;
  color:var(--clrDesatBlue);
  font-size: 12px;
  letter-spacing: 5px;
}

.card-footer {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
}

.seperator{
  color:#fff;
  margin-top: 50px;
  margin-bottom: 30px;
}

/*CARD OVERVIEW*/
.card-overview{
  background: var(--clrLightGrayishBlue);
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 25px;
 }

 .light-theme .card-overview{
  background: var(--clrLightTheme);
  color:#000;
 }

 .overview-header{
  margin-bottom:20px;
 }

 .card-view-body {
  display: flex;
  justify-content: space-between;
  align-items:baseline;
 }

 .card-view-body h2{
  font-size: 30px;
 }

 .percentage{
  font-size: 12px;
  font-weight: 700;
 }

